@media screen and (max-width: 767px) {
  .competencies {
    gap: 41px;

    &__list {
      grid-template-columns: 1fr;
      row-gap: 40px;
    }
  }
}

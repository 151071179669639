.contacts_page {
  padding-top: 80px;
  padding-bottom: 120px;
  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 80px; }
  @media (max-width: 575px) {
    padding-bottom: 0; }
  .map_block {
    @media (max-width: 767px) {
      margin-top: 40px; }
    @media (max-width: 575px) {
      margin-left: -15px;
      margin-right: -15px; }
    iframe {
      display: block;
      width: 100%;
      @media (max-width: 767px) {
        height: 240px; } } }
  h1 {
    margin-bottom: 75px;
    @media (max-width: 767px) {
      margin-bottom: 40px; } }
  .contacts-list {
    p {
      line-height: 24px;
      margin: 0;
      & + p {
        margin-top: 8px; }
      a {
        display: inline-block;
        transition: all 0.15s ease-in-out 0s;
        backface-visibility: hidden;
        font-weight: bold;
        align-self: flex-start;
        color: #252525;
        border-bottom: 1px solid #1969df;
        box-shadow: none;
        &:hover {
          box-shadow: inset 0 0 0 rgba(25, 105, 223, 0%), 0 2px 0 #1969df; } } } }
  .contacts-cities {
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-top: 30px; }
    img {
      display: block;
      margin-bottom: 12px; }
    p {
      margin: 0;
      b {
        font-size: 18px;
        line-height: 28px; }
      a {
        color: #252525;
        letter-spacing: -0.3px; }
      & + p {
        margin-top: 8px; } } } }

$animation-speed: 0.25s;

@font-face {
  font-family: "PFBagueSansPro";
  src: url("./fonts/PFBagueSansPro.woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "PFBagueSansPro";
  src: url("./fonts/PFBagueSansPro-Bold.woff");
  font-style: normal;
  font-weight: bold; }

@font-face {
  font-family: "Spectral";
  src: url("./fonts/Spectral-Regular.woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Spectral";
  src: url("./fonts/Spectral-Italic.woff");
  font-style: italic;
  font-weight: normal; }

@font-face {
  font-family: Akrobat;
  src: url("./fonts/Akrobat-Bold.eot");
  src: url("./fonts/Akrobat-Bold.eot?#iefix") format("embedded-opentype"),
  url("./fonts/Akrobat-Bold.woff") format("woff"),
  url("./fonts/Akrobat-Bold.ttf") format("truetype") {}
  font-weight: normal;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: Akrobat;
  src: url("./fonts/Akrobat-SemiBold.eot");
  src: url("./fonts/Akrobat-SemiBold.eot?#iefix") format("embedded-opentype"),
  url("./fonts/Akrobat-SemiBold.woff") format("woff"),
  url("./fonts/Akrobat-SemiBold.ttf") format("truetype") {}
  font-weight: normal;
  font-style: normal;
  font-weight: 600; }

i {
  font-family: "Spectral"; }

[data-aos^=bg_animate][data-aos^=bg_animate] {
  & .text {
    transition-delay: $animation-speed * 2!important;
    transition: all $animation-speed ease-in-out 0s;
    opacity: 0!important; }
  &:after {
    right: 100%!important;
    transition-delay: $animation-speed * 2!important;
    transition: all $animation-speed ease-in-out 0s; } }

[data-aos^=bg_animate][data-aos^=bg_animate].aos-animate {
  & .text {
    opacity: 1!important; }
  &:after {
    right: 0!important; } }
//opacity: 1
////transform: translateZ(0)

html {
 }  //scroll-behavior: smooth

.container {
  @media (min-width: 1200px) {
    max-width: 1332px; } }

html {
  max-width: 100vw;
  overflow-x: hidden; }

body {
  margin: 0;
  padding: 0;
  font: 16px "PFBagueSansPro", sans-serif;
  color: #252525;
  background-color: #fafafa;
  line-height: 1.5;
  min-width: 320px;
  width: 100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  a {
    text-decoration: none!important;
    outline: none!important; }

  main {
    padding: 89px 50px 0;
    position: relative;
    z-index: 1;
    @media (max-width: 1199px) {
      padding: 89px 0 0; }
    @media (max-width: 991px) {
      padding: 81px 0 0; }
    @media (max-width: 767px) {
      padding: 56px 0 0; }
    h1 {
      font-size: 60px;
      line-height: 71px;
      font-weight: bold;
      @media (max-width: 1199px) {
        font-size: 50px;
        line-height: 1.18; }
      @media (max-width: 767px) {
        font-size: 32px;
        line-height: 34px; } }

    h2 {
      font-size: 48px;
      letter-spacing: 1px;
      line-height: 1.18;
      font-weight: bold;
      @media (max-width: 1199px) {
        font-size: 40px; }
      @media (max-width: 767px) {
        letter-spacing: 0.25px;
        font-size: 34px; } }

    h3 {
      font-family: "PFBagueSansPro";
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      margin-bottom: 24px; } } }

.mb80 {
  margin-bottom: 80px!important;
  @media (max-width: 1199px) {
    margin-bottom: 60px!important; }
  @media (max-width: 767px) {
    margin-bottom: 56px!important; } }

.italic_link {
  color: #252525;
  font-family: Spectral;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: 24px;
  transition: all $animation-speed ease-in-out 0s;
  box-shadow: inset 0 0 0 rgba(25, 105, 223, 0%), 0 1px 0 rgba(25, 105, 223, 40%);
  margin: 9px 0;
  display: block;
  &:hover {
    color: #252525;
    box-shadow: inset 0 0 0 rgba(25, 105, 223, 0%), 0 2px 0 #1969df; } }
.brief {
  display: inline-flex;
  align-self: flex-start;
  flex-flow: column;
  transition: all $animation-speed ease-in-out 0s;
  backface-visibility: hidden;
  &:hover {
    opacity: 0.75; }
  &__desc {
    font-style: italic;
    font-family: "Spectral", sans-serif;
    ine-height: 1.5; }
  &__text {
    margin-top: 8px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 1.2; } }

.MuiButton-root {
  font-family: "PFBagueSansPro" !important; }

.btn {
  position: relative;
  height: 42px!important;
  line-height: 42px!important;
  padding: 0 16px!important;
  border-radius: 21px!important;
  display: inline-flex;
  align-items: center;
  color: #fff;
  background-color: #1969df!important;
  transition: all $animation-speed ease-in-out 0s;
  backface-visibility: hidden;
  cursor: pointer;
  border: 0;
  box-shadow: none!important;
  &.Mui-disabled {
    background-color: #dedede!important;
    opacity: 1!important; }
  .MuiButton-label {
    svg {
      width: 18px;
      height: 18px;
      fill: #ffffff;
      margin-right: 12px; }
    span {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.75px;
      line-height: 1;
      font-family: "PFBagueSansPro"!important;
      margin-bottom: -2px; } }
  &:hover {
    background-color: #2f76e1; } }

.MuiInputBase-input {
  font-family: PFBagueSansPro !important; }

.container-fluid {
  padding: 0 110px;
  @media (max-width: 1199px) {
    padding: 0 50px; }
  @media (max-width: 991px) {
    padding: 0 30px; }
  @media (max-width: 575px) {
    padding: 0 15px; } }

.w-bottom-bar-right.w-css-reset {
  display: none !important; }

.title {
  &.bottom-wave {
    position: relative;
    margin-bottom: 26px;
    padding-bottom: 16px;
    @media (max-width: 575px) {
      margin-bottom: 32px; }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 66px;
      height: 12px;
      background-image: url('./pages/assets/wave.png');
      background-size: contain; } } }

.col-2_block {
  display: flex;
  flex-wrap: wrap;
  //align-items: center
  align-items: stretch;
  & > div {
    width: 50%;
    @media (max-width: 767px) {
      width: 100%; }
    &.media,
    &.color_block {
      position: relative;
      pointer-events: none;
      * {
        pointer-events: none; }
      &:first-child {
        .wistia_responsive_padding,
        .color_block__content {
          position: relative;
          margin-left: -20px;
          margin-right: -55px;
          width: calc(100% + 20px + 55px);
          @media (max-width: 1199px) {
            margin-left: -15px;
            width: calc(100% + 15px + 55px); }
          @media (max-width: 991px) {
            margin-left: -15px;
            margin-right: -30px;
            width: calc(100% + 15px + 30px); }
          @media (max-width: 575px) {
            margin-left: -15px;
            margin-right: -15px;
            width: calc(100% + 15px + 15px); } } }
      &:last-child {
        .wistia_responsive_padding,
        .color_block__content {
          position: relative;
          margin-right: -20px;
          margin-left: -55px;
          width: calc(100% + 20px + 55px);
          @media (max-width: 1199px) {
            margin-right: -15px;
            width: calc(100% + 15px + 55px); }
          @media (max-width: 991px) {
            margin-right: -15px;
            margin-left: -30px;
            width: calc(100% + 15px + 30px); }
          @media (max-width: 575px) {
            margin-right: -15px;
            margin-left: -15px;
            width: calc(100% + 15px + 15px); } } } }
    &.content {
      position: relative;
      .title {
        position: relative;
        margin-bottom: 26px;
        padding-bottom: 16px;
        @media (max-width: 575px) {
          margin-bottom: 32px; }
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 66px;
          height: 12px;
          background-image: url('./pages/assets/wave.png');
          background-size: contain; } }
      .text {
        p {
          margin: 0;
          font-size: 16px;
          line-height: 1.5;
          & + p {
            margin-top: 16px; }
          .italic_link {
            font-weight: bold;
            margin: 0;
            font-size: 16px;
            line-height: 24px;
            pointer-events: none; }
          & + img {
            margin-top: 16px; } }
        & + * {
          margin-top: 32px;
          @media (max-width: 575px) {
            margin-top: 24px;
            @media (max-width: 575px) {
              margin-bottom: 0; } } } }
      .italic_link {
        display: inline-block; }
      &:first-child {
        padding-right: calc(50px + 55px);
        @media (min-width: 1280px) {
          padding-right: calc(120px + 55px); }
        @media (max-width: 991px) {
          padding-right: calc(30px + 30px); }
        @media (max-width: 767px) {
          padding-right: 0;
          margin-bottom: 40px; }
        @media (max-width: 575px) {
 } }          //padding: 0
      &:last-child {
        padding-left: calc(50px + 55px);
        @media (min-width: 1280px) {
          padding-left: calc(120px + 55px); }
        @media (max-width: 991px) {
          padding-left: calc(30px + 30px); }
        @media (max-width: 767px) {
          margin-top: 40px;
          padding-left: 0; }
        @media (max-width: 575px) {
          padding: 0; } } }
    &.media {
      .react-parallax {
        display: block;
        overflow: hidden!important;
        height: 100%;
        //width: 100%
        margin-left: -20px;
        margin-right: -55px;
        width: calc(100% + 20px + 55px);
        @media (max-width: 1199px) {
          margin-left: -15px;
          width: calc(100% + 15px + 55px); }
        @media (max-width: 991px) {
          margin-left: -15px;
          margin-right: -30px;
          width: calc(100% + 15px + 30px); }
        @media (max-width: 767px) {
          margin-left: -30px;
          margin-right: -30px;
          width: calc(100% + 30px + 30px);
          height: 300px; }
        @media (max-width: 575px) {
          height: 250px; }
        img {
          width: 100%; } } }
    &.media,
    &.color_block,
    &.content {
      display: flex;
      flex-wrap: wrap;
      align-items: center; } } }

.col-2_block {
    & .color_block {
      pointer-events: auto;
      .color_block__content {
        position: relative;
        height: 100%;
        padding: 48px 40px;
        color: #fff;
        @media (max-width: 767px) {
          padding: 40px; }
        @media (max-width: 575px) {
          padding: 40px 15px; }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #1969df;
          z-index: 0; }
        .text {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          z-index: 2;
          & > div {
            width: 50%;
            @media (max-width: 991px) {
              width: 100%;
              & + div {
                margin-top: 40px; } }
            h3 {
              position: relative;
              padding-top: 24px;
              margin-bottom: 30px;
              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background-image: url(./pages/assets/wave-white.png);
                background-size: contain;
                width: 66px;
                height: 12px; } }
            span {
              display: block;
              line-height: 28px;
              position: relative;
              padding-left: 16px;
              & + span {
                margin-top: 2px; }
              &:before {
                content: "—";
                font-family: "PFBagueSansPro";
                font-size: 16px;
                line-height: 28px;
                display: block;
                position: absolute;
                top: 0;
                left: 0; } } } } } } }

.bodyWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column; }
  footer {
    flex: 0 0 auto; } }

.title_video_header {
  width: 100%;
  position: relative;
  min-height: calc(100vh - 86px);
  display: flex;
  align-items: center;
  background: #fafafa;
  @media (max-width: 1199px) {
    min-height: calc(100vh - 81px); }
  @media (max-width: 767px) {
    min-height: calc(100vh - 56px); }
  @media (max-width: 575px) {
    background: no-repeat center;
    background-size: cover; }
  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    filter: grayscale(1);
    height: 100%;
    object-fit: cover;
    //opacity: 0.985
    z-index: 0;
    @media (max-width: 575px) {
      opacity: 0; } }
  &__inner {
    width: 100%;
    padding: 0 30px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #fff;
    h1 {
      @media (max-width: 767px) {
        font-size: 48px; } } }
  .arrow_down {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    padding: 24px;
    cursor: pointer;
    @media (max-width: 575px) {
      bottom: 16px; }
    svg {
      display: block;
      width: 26px;
      height: 26px;
      transition: all $animation-speed; }
    &:hover {
      svg {
        fill: #1969df; } } } }

.page404 {
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .container {
    width: 100%;
    max-width: 515px;
    padding-top: 30px;
    padding-bottom: 30px;
    img {
      margin: 0 auto 56px;
      display: block;
      max-width: 278px; }
    p {
      margin: 0;
      span {
        display: block;
        .italic_link {
          display: inline;
          font-size: 16px;
          line-height: 24px; } } } } }

#about_about {
  padding: 120px 0 200px; }

#our_numbers {
  padding: 72px 0;
  background: #1969df;
  color: #fff;
  @media (max-width: 767px) {
    padding: 40px 0; }
  h2 {
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: 48px; } }
  b, i {
    font-size: 24px;
    line-height: 28px;
    display: block; } }

#our_clients {
  padding: 200px 0 120px;
  @media (max-width: 997px) {
    padding: 80px 0 40px; }
  .logo_list {
    @media (max-width: 767px) {
      overflow: auto; }
    .list {
      div {
        margin: 0 -40px;
        position: relative;
        @media (max-width: 1199px) {
          margin: 0 -20px; }
        @media (max-width: 997px) {
          margin: 0 -20px;
          justify-content: center;
          align-items: center;
          display: flex;
          flex-wrap: wrap; }
        @media (max-width: 767px) {
          display: flex;
          flex-wrap: nowrap;
          justify-content: left; }
        img {
          margin: 100px 40px 0;
          width: calc(100% / 5 - 80px);
          @media (max-width: 1199px) {
            margin: 80px 20px 0;
            width: calc(100% / 5 - 40px); }
          @media (max-width: 997px) {
            margin: 60px 20px 0;
            width: calc(100% / 5 - 40px); }
          @media (max-width: 767px) {
            margin: 24px 20px 0;
            width: 144px; } } } } } }

.slick-vertical .slick-slide {
  border: 0!important; }

.slick-slide {
  top: 0; }

.paralax_400 {
  height: 400px;
  @media (max-width: 767px) {
    height: 250px; }
  @media (max-width: 575px) {
    height: 150px; } }

.services {
  padding: 120px 0 120px;
  @media (max-width: 997px) {
    padding: 100px 0; }
  @media (max-width: 767px) {
    padding: 60px 0; }

  .interactive {
    padding: 200px 0;
    @media (max-width: 767px) {
      padding: 120px 0; } }
  .services_list {
    .service_card {
      margin-top: 120px;
      @media (max-width: 997px) {
        margin-top: 60px; }
      @media (max-width: 767px) {
        margin-top: 40px; }
      .ico {
        width: 120px;
        height: 120px;
        background-color: #dde9fa;
        border-radius: 50%;
        position: relative;
        @media (max-width: 767px) {
          width: 100px;
          height: 100px; }
        img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); } }
      h4 {
        margin: 30px 10px 12px 0;
        font-weight: bold;
        line-height: 1;
        @media (max-width: 767px) {
          margin: 16px 0 8px;
          font-size: 18px; } }

      span {
        display: block;
        margin-bottom: 16px;
        margin-right: 10px;
        @media (max-width: 767px) {
          margin-bottom: 12px;
          margin-right: 0; } }
      i {
        display: block;
        margin-right: 10px;
        @media (max-width: 767px) {
          margin-right: 0; } } } }
  & + div:not(.row) {
 } }    //padding: 0

#footer_form {
  height: auto!important; }

#about_about {
  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 80px; }
  .col-2_block {
    .media {
      position: relative;
      img {
        width: 100%; } } } }


.review {
  padding: 120px 0;
  @media (max-width: 767px) {
    padding: 40px 0; }
  &Wrapper {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      display: block;
      position: relative; }
    .ava {
      width: 200px;
      padding-top: 64px;
      @media (max-width: 991px) {
        width: 100px; }
      @media (max-width: 767px) {
        position: absolute;
        top: 52px;
        left: 0;
        padding-top: 0; }
      div {
        width: 100%;
        background: #ccc;
        border-radius: 50%;
        background-size: cover;
        position: relative;
        &:before {
          content: "";
          display: block;
          width: 100%;
          padding-top: 100%; }
        span {
          font-size: 156px;
          position: absolute;
          top: -146px;
          left: -24px;
          color: #1969df;
          font-weight: bold;
          @media (max-width: 991px) {
            font-size: 78px;
            top: -73px;
            left: 0; } } } }
    .text {
      width: calc(100% - 200px);
      padding-left: 40px;
      @media (max-width: 991px) {
        width: calc(100% - 100px); }
      @media (max-width: 767px) {
        width: 100%;
        padding-left: 0; }
      h2 {
        font-size: 34px;
        margin-bottom: 24px;
        @media (max-width: 767px) {
          font-size: 24px;
          margin-bottom: 148px; } }
      .name {
        padding: 0 0 24px;
        span {
          display: block;
          & + span {
            opacity: 0.5;
            a {
              font-family: Spectral;
              font-style: italic;
              color: #252525;
              border-bottom: 1px solid #1969df; } } } } } } }

//.onlyScreen
//  .slick-track
//    display: flex!important
//    .slick-slide
//      display: flex!important
//      height: auto!important

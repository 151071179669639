$animation-speed: 0.25s;

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 5;
  background-color: #fff;
  padding: 25px 0;
  transition: all $animation-speed ease-in-out;
  backface-visibility: hidden;
  @media (max-width: 991px) {
    padding: 21px 0; }
  @media (max-width: 767px) {
    padding: 16px 0; }
  & > div {
    padding: 0 50px;
    @media (max-width: 1199px) {
      padding: 0 0 0 15px; } }
  .header-container {
    width: 100%;
    display: flex;
    .logo {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      transition: all $animation-speed ease-in-out;
      backface-visibility: hidden;
      text-decoration: none;
      &_image {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 310px;
        height: 40px;
        @media (max-width: 991px) {
          width: 204px; }
        img {
          width: 100%;
          height: 100%;
          @media (max-width: 991px) {
            margin-top: -4px; } } }
      &_description {
        display: block;
        margin-left: 24px;
        font-size: 16px;
        line-height: 1;
        color: #252525;
        @media (max-width: 991px) {
          display: none; } } }
    .contacts {
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      @media (max-width: 767px) {
        display: none; }
      .tel,
      .time {
        width: 100%;
        text-align: right; }
      .tel {
        font-size: 16px;
        line-height: 1.5;
        color: #252525; }
      .time {
        line-height: 1.33;
        letter-spacing: 0.4px;
        font-size: 12px;
        color: #444444; } }
    .mobile_menu__button {
      padding: 4px 15px 4px 20px;
      display: none;
      @media (max-width: 767px) {
        margin-left: auto; }
      @media (max-width: 1199px) {
        display: flex;
        align-items: center; }
      &__icon {
        width: 35px;
        height: 16px;
        position: relative;
        cursor: pointer;
        transition: all $animation-speed ease-in-out;
        div {
          height: 2px;
          background-color: #252525;
          position: absolute;
          transition: all $animation-speed ease-in-out;
          & + div {
            margin-top: 5px; }
          &:nth-child(1) {
            top: 0;
            right: 0;
            left: initial;
            width: 85%; }
          &:nth-child(2) {
            top: 50%;
            right: 0;
            left: initial;
            margin-top: -1px;
            width: 100%; }
          &:nth-child(3) {
            bottom: 0;
            right: 0;
            left: initial;
            width: 85%; } }
        @media (min-width: 991px) {
          &:hover {
            div {
              &:nth-child(1) {
                width: 100%; }
              &:nth-child(2) {
                width: 85%; }
              &:nth-child(3) {
                width: 100%; } } } } }
      &.open {
        .mobile_menu__button__icon {
          transform: rotate(90deg);
          div {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              width: 100%; }
            //  margin: 0
            //&:nth-child(1),
            //&:nth-child(2)
            //  top: 50%
            //  transform: rotate(45deg)
            &:nth-child(1) {
              transform: translate3d(0, 8px, 0) rotate(45deg); }
            &:nth-child(2) {
              transform: translate3d(0, 0, 0);
              opacity: 0; }
            &:nth-child(3) {
              transform: translate3d(0, -6px, 0) rotate(-45deg); } } }
        @media (min-width: 991px) {
          &:hover {
            .mobile_menu__button__icon {
              transform: rotate(0deg) scale(0.8); } } } } } } }

.left-bar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 5;
  width: 50px;
  .menu__button {
    padding: 4px 18px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 15px;
    cursor: pointer;
    transform: translateY(-50%);
    &__icon {
      width: 35px;
      height: 16px;
      position: relative;
      cursor: pointer;
      transition: all $animation-speed ease-in-out;
      div {
        height: 2px;
        background-color: #252525;
        position: absolute;
        transition: all $animation-speed ease-in-out;
        & + div {
          margin-top: 5px; }
        &:nth-child(1) {
          top: 0;
          right: 0;
          left: initial;
          width: 85%; }
        &:nth-child(2) {
          top: 50%;
          right: 0;
          left: initial;
          margin-top: -1px;
          width: 100%; }
        &:nth-child(3) {
          bottom: 0;
          right: 0;
          left: initial;
          width: 85%; } }
      @media (min-width: 991px) {
        &:hover {
          div {
            &:nth-child(1) {
              width: 100%; }
            &:nth-child(2) {
              width: 85%; }
            &:nth-child(3) {
              width: 100%; } } } } }
    .text {
      transition: all $animation-speed ease-in-out;
      cursor: pointer;
      span {
        display: block;
        line-height: 1;
        right: -32px;
        font-size: 14px;
        position: absolute;
        transform: translate(-50%, -50%) rotate(-90deg);
        text-align: center; } }
    &.open {
      .menu__button__icon {
        transform: rotate(90deg);
        div {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            width: 100%; }
          &:nth-child(1) {
            transform: translate3d(0, 8px, 0) rotate(45deg); }
          &:nth-child(2) {
            transform: translate3d(0, 0, 0);
            opacity: 0; }
          &:nth-child(3) {
            transform: translate3d(0, -6px, 0) rotate(-45deg); } } }
      .text {
        opacity: 0; }
      @media (min-width: 991px) {
        &:hover {
          .menu__button__icon {
            transform: rotate(0deg); } } } } } }


.right-bar {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 5;
  width: 50px; }

.left-bar,
.right-bar {
  @media (max-width: 1199px) {
    display: none; } }

.menu {
  position: fixed;
  z-index: 4;
  top: 89px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  pointer-events: none;
  @media (max-width: 1199px) {
    top: 81px;
    top: 0;
    left: 0;
    right: 0; }
  @media (max-width: 767px) {
    top: 56px;
    top: 0; }
  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    height: calc(100% - 56px);
    margin-top: 56px; }
  & > div {
    position: relative;
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 0 10%;
    @media (max-width: 767px) {
      padding: 0 30px; }
    @media (max-width: 575px) {
      width: 100%;
      padding: 0;
      flex: 1 0 auto; } }
  & > div:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    transition: transform $animation-speed * 1.5; //cubic-bezier(0.88, 0.01, 0.15, 0.99)
    transform: scaleX(0);
    transform-origin: left top;
    background-color: #fff;
    transition-delay: $animation-speed * 1.5;
    @media (max-width: 575px) {
      transition-delay: $animation-speed + (0.08s * 6)!important;
      transform: scaleX(1);
      opacity: 0;
      background: #ffffff;
      transition: all $animation-speed * 2; } }
  & > .contacts {
    @media (max-width: 575px) {
      flex: 0 0 auto;
      padding: 16px!important; }
    &:after {
      transform: scaleY(0);
      background-color: #1969df;
      transform-origin: top left;
      transition-delay: $animation-speed * 1.5 + 0.25s;
      @media (max-width: 575px) {
        transition-delay: $animation-speed + (0.08s * 6)!important;
        transform: scaleY(1);
        opacity: 0;
        background: #ffffff;
        transition: all $animation-speed * 2; } } }
  & > .list {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        opacity: 0;
        transform: translateX(40px);
        transition: all $animation-speed * 2 cubic-bezier(0.075, 0.82, 0.165, 1);
        padding: 3px 0;
        @media (max-width: 575px) {
          padding: 0; }
        &:nth-child(1) {
          transition-delay: 0.1s; }
        &:nth-child(2) {
          transition-delay: 0.18s; }
        &:nth-child(3) {
          transition-delay: 0.26s; }
        &:nth-child(4) {
          transition-delay: 0.34s; }
        &:nth-child(5) {
          transition-delay: 0.42s; }
        a {
          position: relative;
          display: inline-block;
          transition: all $animation-speed;
          @media (max-width: 767px) {
            display: block;
            width: 100%; }
          button {
            position: relative;
            display: inline-block;
            cursor: pointer;
            font-size: 34px;
            line-height: 1.18;
            letter-spacing: 0.3px;
            font-weight: bold;
            padding: 12px 0;
            color: #252525;
            transition: all $animation-speed;
            border-right: 0;
            &:before {
              content: "";
              position: absolute;
              bottom: 5px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: #1969df;
              transition: transform 0.6s cubic-bezier(0.07, 0.97, 0.46, 0.99);
              transform: scale(0);
              transform-origin: bottom right;
              @media (max-width: 767px) {
                display: none; } }
            &:hover {
              background: initial; }
            @media (max-width: 767px) {
              display: block;
              width: 100%; }
            @media (max-width: 575px) {
              padding: 12px 16px;
              font-size: 24px; }
            span {
              &:first-child {
                text-transform: initial;
                text-align: left; }
              &:nth-child(2) {
                border-radius: 0!important;
                span {
                    background-color: #1969df; }
                @media (min-width: 768px) {
                  display: none; } } } }
          &.active {
            color: #1969df;
            &:before {
              display: none; } } } } } }

  & > .contacts {
    @media (max-width: 575px) {
      padding: 0 16px; }
    .inner {
      color: #fff;
      @media (max-width: 575px) {
        color: #252525; }
      & > * {
        opacity: 0;
        transform: translateY(40px);
        transition: all $animation-speed * 2 cubic-bezier(0.075, 0.82, 0.165, 1);
        &:nth-child(1) {
          transition-delay: $animation-speed; }
        &:nth-child(2) {
          transition-delay: $animation-speed + 0.08s; } }
      & > div {
        display: flex;
        flex-flow: column;
        margin-bottom: 70px;
        @media (max-width: 575px) {
          margin-bottom: 0; }
        p {
          margin: 0 0 5px; }
        div {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          flex-flow: column;
          justify-content: flex-start;
          display: flex;
          a {
            display: inline-block;
            transition: all 0.15s ease-in-out 0s;
            backface-visibility: hidden;
            font-weight: bold;
            align-self: flex-start;
            color: #fff;
            box-shadow: inset 0 0 0 #ffffff00, 0 1px 0 #ffffff66;
            &:hover {
              box-shadow: inset 0 0 0 #ffffff, 0 2px 0 #ffffff; }
            @media (max-width: 575px) {
              color: #252525;
              border-bottom: 1px solid #1969df;
              box-shadow: none; }
            & + a {
              margin-top: 5px; } } } }

      .brief_wrapper {
        margin-bottom: 0;
        @media (max-width: 575px) {
          display: none; }
        * {
          color: #fff; } } } }


  &.open {
    pointer-events: auto;
    & > div:after {
      transition-delay: 0s;
      transform: scaleX(1);
      @media (max-width: 575px) {
        opacity: 1;
        transition-delay: 0s!important; } }
    & > .contacts:after {
      transform: scaleY(1);
      transition-delay: $animation-speed;
      @media (max-width: 575px) {
        opacity: 1;
        transition-delay: 0s!important; } }
    & > .list {
      ul {
        li {
          opacity: 1;
          transform: translateY(0);
          &:nth-child(1) {
            transition-delay: $animation-speed + 0.08s; }
          &:nth-child(2) {
            transition-delay: $animation-speed + (0.08s * 2); }
          &:nth-child(3) {
            transition-delay: $animation-speed + (0.08s * 3); }
          &:nth-child(4) {
            transition-delay: $animation-speed + (0.08s * 4); }
          &:nth-child(5) {
            transition-delay: $animation-speed + (0.08s * 5); }
          a button:hover:before {
            transform-origin: bottom left;
            transform: scaleX(1); } } } }
    & > .contacts {
      .inner {
        & > * {
          opacity: 1;
          transform: translateY(0);
          &:nth-child(1) {
            transition-delay: $animation-speed + (0.08s * 6); }
          &:nth-child(2) {
            transition-delay: $animation-speed + (0.08s * 7); } } } } } }

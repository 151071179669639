.reviews {
  padding: 80px 0 120px;
  background-color: #fafafa !important;

  .slider-reviews {
    min-height: 340px;
    height: min-content;

    &__dots {
      font-family: Spectral;
      font-size: 18px;
      font-weight: bold;
      position: absolute;
      top: -78px;
      right: 53px;

      span {
        font-size: 14px;
      }
    }

    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-slide {
      > div {
        height: 100%;
      }

      &:not(.slick-active) {
        height: 400px;
        pointer-events: none;
      }
    }

    .slick-arrow {
      position: absolute;
      top: -78px;
      right: 0;
      z-index: 5;
      cursor: pointer;
    }

    .slick-prev {
      right: 102px;
      transform: rotate(180deg);
    }
  }

  .title {
    margin-bottom: 32px !important;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 200px auto;
    gap: 80px;
    padding: 48px 60px;
    background: #fff;
  }

  &__ava {
    padding: 0 !important;
  }

  &__ava-image {
    background-color: #000 !important;
  }

  &__text {
    padding: 0 !important;
    width: 100% !important;
  }

  &__name {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    margin-bottom: 2px;
    width: max-content;
  }

  &__position {
    opacity: 0.5;
    margin: 0;

    a {
      font-style: italic;
      color: #252525;
      font-family: "Spectral";
      border: 1px solid #1969df;
      border-width: 0 0 1px 0;

      &:hover {
        color: #1969df;
      }
    }
  }

  &__source {
    opacity: 0.5;
    font-style: italic;
    color: #252525;
    font-family: "Spectral";
    border: 1px solid #1969df;
    border-width: 0 0 1px 0;

    &:hover {
      color: #1969df;
    }
  }
}
